import { EToastType } from '~/types/components'

const DEFAULT_TIPS = { type: EToastType.INFO, text: 'Oops! Something went wrong.' }
type TToastData = typeof DEFAULT_TIPS & { key?: number, ms?: number }

export function useToast() {
  const count = useState(() => 0)
  const prevLastToastDate = useState(() => 0)
  const toastList = useState<Array<TToastData>>(() => [])

  const toast = (info?: TToastData | string) => {
    if (!info)
      info = { ...DEFAULT_TIPS, key: count.value += 1 }
    if (typeof info === 'string')
      info = { ...DEFAULT_TIPS, key: count.value += 1, text: info }

    if (!info.ms)
      info.ms = 3500
    toastList.value.push(info)
    prevLastToastDate.value = +new Date() + info.ms
  }

  return {
    toastList,
    toast,
  }
}
