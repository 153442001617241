<script setup lang="ts">
import SVG_EYE_OPEN_ICON from '~/assets/svg/eye-open-icon.svg'
import SVG_EYE_CLOSE_ICON from '~/assets/svg/eye-close-icon.svg'

const props = withDefaults(defineProps<{
  type?: string
  label: string
  btnDisabled?: boolean
  showCaptchaBtn?: boolean
}>(), {
  type: 'text',
  showCaptchaBtn: false,
})

defineEmits(['tapSendEmail'])

let timer: NodeJS.Timeout | number | undefined
const model = defineModel({ required: true })

const isFocus = ref(false)
const showPassword = ref(false)
const countdownTime = ref(0)

const inputType = computed(() => props.type !== 'password' ? props.type : showPassword.value ? 'text' : 'password')

function countDown() {
  countdownTime.value = 60
  timer = setInterval(() => {
    if (countdownTime.value <= 1)
      clearInterval(timer)

    countdownTime.value -= 1
  }, 1000)
}

const formatCountdownTime = computed(() => `${countdownTime.value < 10 ? '0' : ''}${countdownTime.value}s`)

onUnmounted(() => {
  timer && clearInterval(timer)
})

defineExpose({ countDown })
</script>

<template>
  <div class="relative h-12.5 w-full flex items-end">
    <input
      v-model="model" :type="inputType" class="h-inherit w-full b-none bg-transparent text-3.5 c-#fff c-op-90 font-600 caret-green-5" b="0 b solid gray-4 op-50"
      @blur="isFocus = !isFocus" @focus="isFocus = !isFocus"
    >
    <span
      class="pointer-events-none absolute inset-is-0 origin-lt text-3.5 c-#fff c-op-50 transition-all-300"
      :class="isFocus || model ? 'scale-10/14! top-0 translate-y-0' : '-translate-y-50% top-50%'"
    >
      {{ label }}
    </span>
    <button
      v-if="props.showCaptchaBtn" class="ld-button mis-3 h-11 min-w-19" :class="countdownTime > 0 || props.btnDisabled ? 'ld-button-gray pointer-events-none' : 'ld-button-green'"
      @click="$emit('tapSendEmail')"
    >
      {{ countdownTime > 0 ? formatCountdownTime : $t('personal.obtainBtn') }}
    </button>
    <div v-if="props.type === 'password'" class="absolute inset-ie-0 top-50% size-7.5 flex-center cursor-pointer -translate-y-50%" @click="showPassword = !showPassword">
      <SVG_EYE_OPEN_ICON v-if="!showPassword" />
      <SVG_EYE_CLOSE_ICON v-else />
    </div>
  </div>
</template>
