<script setup lang="ts">
import { overseaLogin } from 'ld-web-sdk'
import { useThrottleFn } from '@vueuse/core'

import type { CallbackTypes } from 'vue3-google-login'
import { CommonInput } from '#components'
import type { TLoginSDKGoogleResult } from '@/types/api'
import { ELoginDialogType, ELoginSuccessChannels, EToastType } from '@/types/components'

import SVG_PLATFORM_GOOGLE from '~/assets/svg/platform/google.svg'

const captcha = ref('')
const newPassword = ref('')
const renderType = ref(ELoginDialogType.LOGIN)
const isConfirmPrivacy = ref(false)
const signUpCaptchaRef = ref<InstanceType<typeof CommonInput> | null>(null)
const resetCaptchaRef = ref<InstanceType<typeof CommonInput> | null>(null)

const payload = reactive({
  email: '',
  password: '',
})

const { toast } = useToast()
const { waitSdkInit, userInfo, loginSource } = useUserInfo()
const loginInstance = overseaLogin.createLogin()

const isDisabledSignBtn = computed(() => !payload.email || !payload.password || !isConfirmPrivacy.value || !captcha.value)

const sendEmail = useThrottleFn(async () => {
  await waitSdkInit.value
  await overseaLogin.sendEmail({ email: payload.email, sendType: renderType.value === ELoginDialogType.SIGNUP ? 'reg' : 'updatePwd', languageCode: 'GB' })
  renderType.value === ELoginDialogType.SIGNUP ? signUpCaptchaRef.value?.countDown() : resetCaptchaRef.value?.countDown()
  toast({ type: EToastType.SUCCESS, text: `Verification code has been sent to your email` })
}, 2000)

const tapSignup = useThrottleFn(async () => {
  if (!payload.email || !payload.password || !captcha.value)
    return
  try {
    await waitSdkInit.value
    const response = await loginInstance.email({ email: payload.email, auth: captcha.value, pwd: payload.password })
    // login success
    userInfo.value = Object.assign(response, { email: payload.email })
    try {
      const { uid, email, nickname } = userInfo.value
      window?.dataLayer?.push({ event: 'login-success', uid, email, nickname, type: ELoginSuccessChannels.REGISTER, source: loginSource.value })
    }
    catch (e) {}
  }
  catch (e) {
    const err = e as { message: string }
    toast(err.message)
  }
}, 1000)

const tapLogin = useThrottleFn(async () => {
  try {
    await waitSdkInit.value
    const response = await loginInstance.emailWithPwd({
      email: payload.email,
      auth: payload.password,
    })

    // login success
    userInfo.value = Object.assign(response, { email: payload.email })

    try {
      const { uid, email, nickname } = userInfo.value
      window?.dataLayer?.push({ event: 'login-success', uid, email, nickname, type: ELoginSuccessChannels.EMAIL, source: loginSource.value })
    }
    catch (e) {}
  }
  catch (e) {
    const err = e as { message: string }
    toast(err.message)
  }
}, 1000)

const tapResetPassword = useThrottleFn(async () => {
  try {
    await waitSdkInit.value
    const { emailUpdate } = overseaLogin.createUpdateUser()
    await emailUpdate({
      auth: captcha.value,
      email: payload.email,
      pwd: payload.password,
    })
    // reset success
    renderType.value = ELoginDialogType.LOGIN
  }
  catch (e) {
    const err = e as { message: string }
    toast(err.message)
  }
}, 1000)

const tapGoogleLogin: CallbackTypes.CredentialCallback = useThrottleFn(async (response) => {
  try {
    await waitSdkInit.value
    const data = await loginInstance.google({ auth: response.credential }) as TLoginSDKGoogleResult
    userInfo.value = Object.assign(data, { email: data.thirdUserInfos[0].thirdEmail })
    try {
      const { uid, email, nickname } = userInfo.value
      window?.dataLayer?.push({ event: 'login-success', uid, email, nickname, type: ELoginSuccessChannels.GOOGLE, source: loginSource.value })
    }
    catch (e) {}
  }
  catch (e) {
    const err = e as { message: string }
    toast(err.message)
  }
}, 2000)
</script>

<template>
  <div lt-lg:landscape="relative w-100vw" lt-md:portrait="relative w-100vw">
    <!-- fix scroll lock with 0.5px -->
    <div class="absolute left-0 hidden h-0.25 w-full bg-gray-3 -bottom-0.25" lt-lg:landscape="block" lt-md:portrait="block" />

    <div
      class="relative z-10 w-110 flex flex-col rd-4 bg-gray-3 p-10"
      lt-lg:landscape="w-full rd-0 px-5 py-6" lt-md:portrait="px-5 py-6 w-full rd-0"
    >
      <!-- close pin -->
      <div class="sticky left-0 top-0 z-2 hidden h-6 w-full flex-center bg-gray-3" lt-md:landscape="flex" lt-md:portrait="flex">
        <!-- <div class="h-1.5 w-11 rd-1 bg-gray-4" /> -->
      </div>

      <div class="relative flex-center">
        <span class="text-8 c-#fff c-op-90 font-[Bebas_Neue]">{{ [$t('personal.loginTitle'), $t('personal.signTitle'), $t('personal.resetTitle')][renderType] }}</span>
        <button v-if="renderType !== ELoginDialogType.LOGIN" class="absolute inset-is-0 size-8 flex-center rd-2 bg-gray-4 transition-300" hover="bg-gray-5" @click="renderType = ELoginDialogType.LOGIN">
          <svg class="rotate-180" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 12L9 8L7 4" stroke="white" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </button>
      </div>
      <!-- login -->
      <section v-show="renderType === ELoginDialogType.LOGIN" class="flex flex-col">
        <div class="grid mt-10 gap-4" lt-lg="mt-6 gap-4">
          <CommonInput v-model="payload.email" :label="$t('personal.emailLabel')" />
          <CommonInput v-model="payload.password" :label="$t('personal.passwordLabel')" type="password" />
        </div>
        <button
          class="mt-4 cursor-pointer self-end bg-transparent text-3 c-#fff c-op-30 transition-300 hover:c-op-50"
          @click="renderType = ELoginDialogType.RESET"
        >
          {{ $t('personal.forgotBtn') }}
        </button>

        <div class="mt-6 flex" lt-lg="mt-10">
          <div class="ld-button relative mie-4 h-11 of-hidden ld-button-gray" w="11 lt-lg:landscape:30">
            <div class="pointer-events-none absolute z-2 size-full flex-center">
              <SVG_PLATFORM_GOOGLE class="size-5" />
              <span class="mis-2 hidden text-3 c-#fff c-op-90 font-600 lt-lg:landscape:block">Google</span>
            </div>
            <GoogleLogin class="size-full op-1" :callback="tapGoogleLogin" />
          </div>
          <button
            class="ld-button h-11 flex-center flex-1 rd-2 px-1 font-600 transition-300"
            :class="!payload.email || !payload.password ? 'ld-button-gray pointer-events-none' : 'ld-button-green'"
            @click="tapLogin"
          >
            {{ $t('personal.loginBtn') }}
          </button>
        </div>

        <div class="mt-10 flex-center text-3" lt-lg:landscape="mt-5" lt-md:portrait="mt-5">
          <span class="c-#fff c-op-50">{{ $t('personal.signTips') }}&nbsp;</span>
          <button class="inline-flex cursor-pointer bg-transparent text-3" c="green-5 hover:green-4 active:green-6" @click="renderType = ELoginDialogType.SIGNUP">
            {{ $t('personal.signBtn') }}
          </button>
        </div>
      </section>

      <!-- sign up -->
      <section v-show="renderType === ELoginDialogType.SIGNUP">
        <div class="grid mt-10 gap-4" lt-lg="mt-6 gap-4">
          <CommonInput v-model="payload.email" :label="$t('personal.emailLabel')" />
          <CommonInput ref="signUpCaptchaRef" v-model="captcha" show-captcha-btn :label="$t('personal.captchaLabel')" :btn-disabled="!payload.email" @tap-send-email.once="sendEmail" />
          <CommonInput v-model="payload.password" :label="$t('personal.passwordLabel')" type="password" />
        </div>

        <button
          class="ld-button mt-6 h-11 w-full flex-center rd-2 transition-300" lt-lg="mt-10"
          :class="isDisabledSignBtn ? 'ld-button-gray pointer-events-none' : 'ld-button-green'"
          @click="tapSignup"
        >
          {{ $t('personal.signBtn') }}
        </button>
        <div class="mt-6 flex flex-center" lt-lg:landscape="mt-5" lt-md:portrait="mt-5">
          <button class="mie-2 size-4 rd-1 bg-gray-4 transition-300 hover:bg-gray-5" @click="isConfirmPrivacy = !isConfirmPrivacy">
            <svg class="translate-x-0.5 -translate-y-0.5" :class="isConfirmPrivacy ? '' : 'op-0'" width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2.51714 5.32191L1.24808 6.16795C0.654342 6.56377 0.654342 7.43623 1.24808 7.83205L5.15006 10.4334C5.61651 10.7443 6.24744 10.6112 6.54842 10.1382L12.5537 0.701258C12.747 0.397496 12.5288 0 12.1688 0C12.0599 0 11.9545 0.0389633 11.8718 0.109848L5 6L3.51906 5.25953C3.19808 5.09904 2.81573 5.12285 2.51714 5.32191Z" fill="#CCFF00" />
            </svg>
          </button>
          <p class="text-3 c-#fff c-op-50">
            {{ $t('personal.agreementTips') }}
            <NuxtLink to="/privacy-policy.html" target="_blank" class="mis-0.5 c-#fff c-op-90">
              {{ $t('personal.privacyBtn') }}
            </NuxtLink>
          </p>
        </div>
      </section>

      <!-- reset password -->
      <section v-show="renderType === ELoginDialogType.RESET">
        <div class="grid mt-10 gap-4" lt-lg="mt-6 gap-4">
          <CommonInput v-model="payload.email" :label="$t('personal.emailLabel')" />
          <CommonInput ref="resetCaptchaRef" v-model="captcha" :label="$t('personal.captchaLabel')" show-captcha-btn :btn-disabled="!payload.email" @tap-send-email.once="sendEmail" />
          <CommonInput v-model="payload.password" :label="$t('personal.newPasswordLabel')" type="password" />
          <CommonInput v-model="newPassword" :label="$t('personal.newPasswordAgainLabel')" type="password" />
        </div>
        <button
          class="ld-button mt-6 h-11 w-full flex-center rd-2 px-2 transition-300" lt-lg="mt-10"
          :class="!payload.email || !captcha || !payload.password || !newPassword || (payload.password !== newPassword) ? 'ld-button-gray pointer-events-none' : 'ld-button-green'"
          @click="tapResetPassword"
        >
          {{ $t('personal.resetBtn') }}
        </button>
      </section>
    </div>
  </div>
</template>
