export function useGlobalContext() {
  interface GlobalContext {
    isFullscreen: boolean
    iosFullScreen: boolean
    gameState: 'playing' | 'queue' | 'idle'
  }

  function initContext(): GlobalContext {
    return {
      isFullscreen: false,
      iosFullScreen: false,
      gameState: 'idle',
    }
  }

  const globalContext = useState<GlobalContext>('EASYFUN-GLOBAL', initContext)

  return {
    globalContext,
  }
}
