/* eslint-disable antfu/top-level-function */
import type { ICommonGameBlockProps } from '~/types/components'

const LOCAL_KEY = 'EASYFUN_GAME_HISTORY'

export const usePlayed = () => {
  const playedList = useState<Array<ICommonGameBlockProps>>(() => [])

  onBeforeMount(() => {
    playedList.value = JSON.parse(localStorage.getItem(LOCAL_KEY) || '[]')
  })

  const addPlayedGame = (game: ICommonGameBlockProps) => {
    // why rest? shake others input to save localstorge space
    const { gameId, gameName, icon, seo, status, banner, gameType } = game
    if (playedList.value.find(v => v.gameId === gameId))
      playedList.value.sort((a, b) => a.gameId === gameId ? -1 : b.gameId === gameId ? 1 : 0)
    else
      playedList.value.unshift({ gameId, gameName, icon, seo, status, banner, gameType })

    localStorage.setItem(LOCAL_KEY, JSON.stringify(playedList.value))
  }

  return {
    playedList,
    addPlayedGame,
  }
}
